import React from 'react'
import './Contact.css'

export default function Contact() {
  return (
    <section id='contact'>
      <h1>Contact</h1>
    </section>
  )
}
