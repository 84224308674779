import React from 'react'
import './Projects.css'

export default function Projects() {
  return (
    <section id='projects'>
      <h1>Projects</h1>
    </section>
  )
}
