import React from 'react'
import './Blog.css'

export default function Blog() {
  return (
    <section id='blog'>
      <h1>Blog</h1>
    </section>
  )
}
