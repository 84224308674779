import React from 'react'
import './About.css'

export default function About() {
  return (
    <section id = 'about'>
      <h1>About</h1>
    </section>
  )
}
